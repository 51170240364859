import React, { Component } from 'react';
import { OrderedMap, Map } from 'immutable';
import { connect } from 'react-redux';
import { getClients } from '../../Actions/Clients';
import { getProfilePlanning } from '../../Actions/ProfilePlanning';
import { getGeneratedWeeks } from '../../Actions/GeneratedWeeks';
import moment from 'moment';
import './Styles/Clients.scss';
import { generateDisplayName } from '../../Utils/Person'; 
import { NavLink } from 'react-router-dom';

const mapStateToProps = (state) => ({
    clients: state.getIn(['clients','collection'], OrderedMap()),
    profileplanning: state.getIn(['profileplanning', 'collection'], OrderedMap()),
    generatedWeeks: state.getIn(['generatedweeks','collection'], OrderedMap())
})

const mapDispatchToProps = (dispatch) => ({
    // getClients: () => dispatch(getClients()),
    getProfilePlanning: () => dispatch((getProfilePlanning())),
    getGeneratedWeeks: () => dispatch(getGeneratedWeeks())
})

const isInFuture = (year, week) => {
    let currentYear = moment().isoWeekYear();
    let currentWeek = moment().isoWeek();

    if(year > currentYear) {
        return true;
    }

    if(week >= currentWeek && year == currentYear) {
        return true;
    }

    return false;

}


class Clients extends Component {


    componentDidMount() {
        let { getClients, getProfilePlanning, getGeneratedWeeks } = this.props;
        // getClients && getClients();
        getProfilePlanning && getProfilePlanning();
        getGeneratedWeeks && getGeneratedWeeks();
    }

    render() {

        let { clients, profileplanning, generatedWeeks } = this.props;

        console.log("Clients");
        console.log("profileplanning", profileplanning);

        clients = clients.filter(x => !x.get('nocaredelivery'));

        profileplanning = profileplanning.filter(x => x.get('type') == 1);
        profileplanning = profileplanning.filter(x => !x.get('isDeleted'));

        let groupedProfilePlanning = profileplanning.groupBy(x => x.get('clientid'))
        groupedProfilePlanning = groupedProfilePlanning.filter((v,i) => clients.get(i));
        
        generatedWeeks = generatedWeeks.filter((week) => isInFuture(week.get('year'), week.get('week')));
        profileplanning = profileplanning.filter(x => isInFuture(x.get('year'), x.get('week')));
        

        return <div className="card clients">
        
            <div className="card-header">
                <h1>Clienten</h1>
            </div>

            <div className="card-body">
            
            
                <div className="week-overview">

                    { groupedProfilePlanning.map((group, clientid) => {

                        return <div key={clientid}>
                            <div className="client">
                                <h2>{clients.get(clientid, Map()).get('gender') == 'M' ? 'Dhr.' : 'Mw.'} {generateDisplayName(clients.get(clientid, Map()))}</h2>
                            </div>
                            <div className="clients-weeks">
                                { generatedWeeks.map((generatedWeek, index) => {

                                    let currentGroup = group
                                    .filter(x => x.get('type') == 1)
                                    .filter(x => x.get('week') == generatedWeek.get('week') && x.get('year') == generatedWeek.get('year'))
                                    


                                    let date = moment().isoWeekYear(generatedWeek.get('year')).isoWeek(generatedWeek.get('week'));
                                    date.startOf('w');
                                    let startOfWeek = Number(date.format('x'));
                                    date.endOf('w');
                                    let endOfWeek = Number(date.format('x'));
                                    
                                    console.log("currentGroup", currentGroup);

                                    let hours = currentGroup.reduce((sum, v) => sum + v.get('durationIndex'), 0) / 4;
                                    let openHours = currentGroup.filter(x => !x.get('freelancerid')).reduce((sum, v) => sum + v.get('durationIndex'), 0) / 4;
                                    let filledHours = hours - openHours;
                                    let isOpen = hours != filledHours;

                                    return <NavLink to={`/clients/${clientid}/${startOfWeek}/${endOfWeek}`} className={"clients-week" + (isOpen ? " open" : "")} key={index.toString()}>
                                        week<br />
                                        <strong>{generatedWeek.get('week')}</strong>
                                        <br /> 
                                        {filledHours}/{hours}

                                    </NavLink>

                                }).toList()}
                            </div>



                        </div>

                    }).toList()}


                </div>
            
            </div>
        
        </div>


    }

}



export default connect(mapStateToProps, mapDispatchToProps)(Clients);